import { useState, useEffect } from "react";

import { Stack } from "@mui/material";
import FileUploadComponent from "../../compnents/FileUpload";
import { companyDocumentsInterface } from "../../interface/Common";


type Props = {
  getCompanyDocuments: (e: companyDocumentsInterface) => void;
  getCompanyDocumentsStatus: (e: boolean) => void;
};

export default function CompanyDocuments({
  getCompanyDocuments,
  getCompanyDocumentsStatus,
}: Props) {
  const [formValues, setFormValues] = useState<companyDocumentsInterface>({
    kra_cert: null,
    cert_of_incorporation: null,
    cr_12: null,
    business_permit: null,
  });

  const fileUpload = (e: any) => {
    setFormValues({
      ...formValues,
      [Object.keys(e)[0]]: e[Object.keys(e)[0]],
    });
  };

  useEffect(() => {
    let status = false;
    if (
      formValues.kra_cert &&
      formValues.cert_of_incorporation &&
      formValues.cr_12 &&
      formValues.business_permit
    ) {
      status = true;
    }

    getCompanyDocuments(formValues);
    getCompanyDocumentsStatus(status);
  }, [formValues, getCompanyDocumentsStatus, getCompanyDocuments]);

  return (
    <>
      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <FileUploadComponent
          text="KRA Certificate"
          nameValue="kra_cert"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={false}
          required
        />
        <FileUploadComponent
          text="Certificate Of Incorporation"
          nameValue="cert_of_incorporation"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={false}
          required
        />
      </Stack>

      <Stack
        direction={{ xs: "column", md: "row" }}
        spacing={1}
        sx={{ width: "100%" }}
      >
        <FileUploadComponent
          text="Recent CR12 (no more than 3 months old)"
          nameValue="cr_12"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={false}
          required
        />
        <FileUploadComponent
          text="Business permit"
          nameValue="business_permit"
          bring={fileUpload}
          customStyling={{ width: "50%" }}
          hideData={false}
          hideDelete={false}
          required
        />
      </Stack>
    </>
  );
}
