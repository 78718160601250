import {
  Box,
  Typography,
  OutlinedInput,
  Stack,
  Autocomplete,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect } from "react";
import { COUNTRIES } from "../constants";
import { ShareholderType } from "../interface/Common";
import { regexNameNumbers } from "../constants";
import { DatePicker } from "@mui/x-date-pickers";
import FileUploadComponent from "./FileUpload";

type Props = {
  id: number;
  addShareHolder: (e: any) => any;
};

export default function Shareholders({ id, addShareHolder }: Props) {
  const date = new Date();

  date.setDate(date.getDate() - 1);

  const todayDate = `${date.getFullYear()}-${
    date.getMonth() + 1
  }-${date.getDate()}`;

  const [sharecon, setShareCon] = useState<ShareholderType>({
    name: "",
    shareholding: 10.01,
    nationality: "119",
    d_o_b: todayDate,
    shareholder_id: "",
    shareholder_id_copy: null,
    htmlid: id,
  });

  const [percentageController, setPercentageController] =
    useState<string>("10.00");

  const setData = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    //Regex for percentage with 2 decimal places
    const regex = /^\d*\.?\d{0,2}$/;

    if (name.includes(`name_${id}`)) {
      setShareCon({ ...sharecon, name: value });
    }
    if (name.includes(`percentage_${id}`)) {
      //Regex to check for letters and special characters except for dot
      const regexLetters = /^[0-9.]*$/;
      if (regexLetters.test(value)) {
        setPercentageController(value);
      }

      if (value === "" || regex.test(value)) {
        setShareCon({ ...sharecon, shareholding: Number(value) });
      }
    }

    if (name.includes(`dob_${id}`)) {
      setShareCon({ ...sharecon, d_o_b: value });
    }

    if (name.includes(`id_${id}`)) {
      setShareCon({ ...sharecon, shareholder_id: value });
    }
  };

  const dirIdAdd = (e: any) => {
    setShareCon({
      ...sharecon,
      shareholder_id_copy: e[`shareholder_id_copy_${id}`],
    });
  };

  useEffect(() => {
    addShareHolder(sharecon);
  }, [sharecon]);

  return (
    <Stack direction="column" spacing={1} my={2}>
      <Typography mt={1} fontSize="16px" fontWeight="bold">
        ULTIMATE BENEFICIAL OWNERS - SHAREHOLDERS {id}
        {/* {id == 1 ? null : (
          <IconButton aria-label="delete" onClick={() => deleteShareHolder(id)}>
            <DeleteIcon />
          </IconButton>
        )} */}
      </Typography>
      <Stack direction="row" spacing={1}>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Name of Ultimate Beneficial Owner{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`name_${id}`}
            type="text"
            size="small"
            value={sharecon.name}
            onChange={setData}
            placeholder={"John Doe / Parent Company Name"}
            fullWidth
            error={
              sharecon.name.length === 0
                ? false
                : !regexNameNumbers.test(sharecon.name)
            }
            name={`name_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Shareholding in %{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            id={`percentage_${id}`}
            type="text"
            size="small"
            value={percentageController}
            onChange={setData}
            placeholder={"minimum 10.00%"}
            fullWidth
            inputProps={{
              maxLength: 5,
            }}
            name={`percentage_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Date of Birth / Date of registration{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <DatePicker
            label=""
            value={dayjs(sharecon.d_o_b)}
            onChange={(newDate) => {
              const organizedDate = `${newDate?.year()}-${
                typeof newDate?.month() === "number" ? newDate?.month() + 1 : 1
              }-${newDate?.date()}`;

              setShareCon((prev: ShareholderType) => ({
                ...prev,
                d_o_b: organizedDate,
              }));
            }}
            format="DD/MM/YYYY"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            slotProps={{
              textField: {
                size: "small",
                helperText: "DD/MM/YYYY",
                sx: {
                  borderRadius: "13px",
                  backgroundColor: "transparent",
                  width: "100%",
                },
              },
            }}
            maxDate={dayjs(todayDate)}
          />
        </Box>

        <Box flex={1}>
          <Typography component="label" fontSize="16px" lineHeight="2">
            Nationality/Country of incorporation{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>

          <Autocomplete
            id={`nationality_${id}`}
            options={COUNTRIES}
            size="small"
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
            value={COUNTRIES[Number(sharecon.nationality)]}
            getOptionLabel={(option) => option.name}
            autoHighlight
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setShareCon({
                  ...sharecon,
                  nationality: String(
                    COUNTRIES.findIndex((x) => x.name === newValue.name)
                  ),
                });
              }
            }}
            renderOption={(props, option) => (
              <Box
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${option.flag?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${option.flag?.toLowerCase()}.png 2x`}
                  alt=""
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />
        </Box>
      </Stack>

      <Stack direction="row" spacing={1}>
        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <Typography component="label" fontSize="14px" lineHeight="2">
            ID/Passport Number/Company Registration Number{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <OutlinedInput
            required
            id={`id_${id}`}
            type="text"
            size="small"
            value={sharecon.shareholder_id}
            onChange={setData}
            placeholder={"12142132"}
            name={`id_${id}`}
            sx={{
              borderRadius: "13px",
              backgroundColor: "white",
            }}
          />
        </Box>

        <Box flex={1} display={"flex"} flexDirection={"column"}>
          <Typography component="label" fontSize="14px" lineHeight="2">
            Upload ID/Passport/Company ownership structure{" "}
            <Typography variant="inherit" display="inline" color="error.main">
              *
            </Typography>
          </Typography>
          <FileUploadComponent
            text="Shareholder Upload"
            nameValue={`shareholder_id_copy_${id}`}
            bring={dirIdAdd}
            required={true}
            dis={false}
            hideDelete={true}
          />
        </Box>
      </Stack>
    </Stack>
  );
}
