export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  boxShadow: 24,
  borderRadius: "20px",
  backgroundColor: "#ffffff",
  pb: 4,
};

export const signupStyles = {
  paperContainer: {
    paddingTop: "32px",
    display: "flex",
    height: "60vh",
    justifyContent: "center",
  },
  paperContainerPhone: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: "90vh",
    alignItems: "center",
  },
  containerPhone: {
    display: "flex",
    justifyContent: "center",
  },

  groups: {
    marginBottom: "0px",
  },
  groupsB: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  textfieldC: {
    margin: "8px 8px 8px 8px",
    width: "20vw",
  },
  textfieldCPhone: {
    margin: "8px 0px 8px 0px",
    width: "100%",
  },
  textfieldB: {
    margin: "8px 8px 8px 8px",
    width: "41vw",
  },
};

export const signinStyles = {
  paperContainer: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "90vh",
  },
  groups: {
    marginBottom: "0px",
  },
  groupsB: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  textfieldC: {
    margin: "8px 8px 8px 8px",
    width: "20vw",
  },
  textfieldCPhone: {
    margin: "8px 0px 8px 0px",
    width: "100%",
  },
  textfieldB: {
    margin: "8px 8px 8px 8px",
    width: "41vw",
  },
};

export const Companystyles = {
  paperContainer: {
    paddingTop: "32px",
    display: "flex",
    height: "60vh",
    justifyContent: "center",
  },
  paperContainerPhone: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: "90vh",
    alignItems: "center",
  },
  containerPhone: {
    display: "flex",
    justifyContent: "center",
  },

  groups: {
    marginBottom: "0px",
  },
  groupsB: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  textfieldC: {
    margin: "8px 8px 8px 8px",
    width: "20vw",
  },
  textfieldCPhone: {
    margin: "8px 0px 8px 0px",
    width: "100%",
  },
  textfieldB: {
    margin: "8px 8px 8px 8px",
    width: "41vw",
  },
};

export const CompanyDetailsstyles = {
  paperContainer: {
    paddingTop: "32px",
    display: "flex",
    height: "60vh",
    justifyContent: "center",
  },
  paperContainerPhone: {
    paddingTop: "32px",
    display: "flex",
    justifyContent: "center",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    height: "90vh",
    alignItems: "center",
  },
  containerPhone: {
    display: "flex",
    justifyContent: "center",
  },

  groups: {
    marginBottom: "0px",
  },
  groupsB: {
    marginBottom: "16px",
    display: "flex",
    justifyContent: "center",
  },
  textfieldC: {
    margin: "8px 8px 8px 8px",
    width: "20vw",
  },
  textfieldCPhone: {
    margin: "8px 0px 8px 0px",
    width: "100%",
  },
  textfieldB: {
    margin: "8px 8px 8px 8px",
    width: "41vw",
  },
};
