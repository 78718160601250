import { useMutation } from "react-query";
import { main_url } from "../../constants";

import axios from "axios";
import AuthContext from "../../context/AuthContext";
import { useContext } from "react";

export const usePost = (url: string, token?: string) => {
  const ctxAuth = useContext(AuthContext);
  const {
    data,
    error,
    reset,
    status,
    mutate,
    mutateAsync,
    isError,
    isIdle,
    isLoading,
    isSuccess,
    isPaused,
  } = useMutation(
    async (collectedData: any) => {
      //check if token is available

      if (!token) {
        return axios
          .post(`${main_url}${url}`, JSON.stringify(collectedData), {
            headers: {
              "Content-Type": "application/json",
            },
            timeout: 30000,
          })
          .catch((err) => {
            console.log(err);
          });
      }

      return axios.post(`${main_url}${url}`, collectedData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onError: (err: any) => {
        const error = err.response.data;
        //check if its a token validation error
        if (error?.code === "token_not_valid") {
          console.log("Token not valid from usePost query");
          ctxAuth.logout();
        } else {
          return err.response.data;
        }
      },
    }
  );

  return {
    data,
    error,
    reset,
    status,
    mutate,
    mutateAsync,
    isError,
    isIdle,
    isLoading,
    isSuccess,
    isPaused,
  };
};
