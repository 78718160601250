import { useState, useCallback } from "react";

import { main_url } from "../constants";

type Extra = {
  method: string;
  headers: {
    "Content-Type"?: string;
    Authorization?: string;
  };
  body: string;
};

export const usePostUpdate = () => {
  const [data, setData] = useState<Record<string, any>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const callApi = useCallback(
    (formData: any, url: string, token?: string, file?: boolean) => {
      setIsLoading(true);
      const url_send = formData ? `${main_url}${url}` : `${url}`;
      const extra: Extra = {
        method: "POST",
        headers: token
          ? !file
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            : { Authorization: `Bearer ${token}` }
          : { "Content-Type": "application/json" },
        body: file ? formData : JSON.stringify(formData),
      };

      fetch(url_send, extra)
        .then((res) => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            throw error;
          }

          return res.status === 205
            ? new Promise((resolve, reject) => {
                resolve({ logged: true });
              })
            : res.json();
        })
        .then((data) => {
          setIsLoading(false);

          setData(data);
        })
        .catch((err) => {
          setIsLoading(false);
          setData({ error: err.message });
        });
    },
    []
  );

  return [data, callApi, isLoading] as const;
};
