import React, { useState, useEffect, useContext } from "react";
import { CompanyDetailsType, ShareholderType } from "../interface/Common";
import secureLocalStorage from "react-secure-storage";
import AuthContext from "./AuthContext";

type Props = {
  children: JSX.Element;
};

type Details = {
  companyDetails: CompanyDetailsType;
  addCompanyDetails: (e: CompanyDetailsType | any) => void;
  shareholdersResponse: ShareholderType[];
  setShareholdersResponse: (e: any) => void;
};

const details: Details = {
  companyDetails: {
    id: 0,
    company_name: "",
    company_reg_date: "",
    company_registration: "",
    kra_pin: "",
    P_O_BOX: "",
    country_of_ops: "",
    industry: "",
    nature_of_business: "",
    number_of_employees: "",
    company_telephone_number: "",
    billing_address: "",
    company_website: "",
    shareholders: [],
  },
  addCompanyDetails: (e: any) => {},
  shareholdersResponse: [],
  setShareholdersResponse: (e: ShareholderType[]) => {},
};

const CompanyPDFContext = React.createContext(details);

export const CompanyPDFContextProvider = ({ children }: Props) => {
  const ctx = useContext(AuthContext);
  const [company, setCompany] = useState<CompanyDetailsType>({
    id: 0,
    company_name: "",
    company_reg_date: "",
    company_registration: "",
    kra_pin: "",
    P_O_BOX: "",
    country_of_ops: "",
    industry: "",
    nature_of_business: "",
    number_of_employees: "",
    company_telephone_number: "",
    billing_address: "",
    company_website: "",
    shareholders: [],
  });

  const [shareResponse, setShareResponse] = useState<ShareholderType[]>([]);

  const addCompany = (e: CompanyDetailsType) => {
    setCompany(e);
    secureLocalStorage.setItem("companyDetails", e);
  };

  const setShareholdersResponse = (e: ShareholderType[]) => {
    setShareResponse(e);
    secureLocalStorage.setItem("shareholders", e);
  };

  useEffect(() => {
    const companyDetails: any = secureLocalStorage.getItem("companyDetails");
    const shareholder: any = secureLocalStorage.getItem("shareholders");
    
    if (companyDetails && shareholder) {
      setCompany(companyDetails);
      setShareResponse(shareholder);
      
    }
  }, []);

  return (
    <CompanyPDFContext.Provider
      value={{
        companyDetails: company,
        addCompanyDetails: addCompany,
        shareholdersResponse: shareResponse,
        setShareholdersResponse: setShareholdersResponse,
      }}
    >
      {children}
    </CompanyPDFContext.Provider>
  );
};

export default CompanyPDFContext;
