import { Dispatch, useContext, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
// context
import AuthContext from "../context/AuthContext";
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */

interface useIdleTimeoutProps {
  onIdle: () => void;
  idleTime: number;
  disabled: boolean;
  handleLogout: () => void;
}
const useIdleTimeout = ({
  onIdle,
  idleTime = 120,
  disabled = false,
  handleLogout,
}: useIdleTimeoutProps) => {
  const idleTimeout = 1000 * idleTime;
  const [isIdle, setIdle] = useState(false);
  const handleIdle = () => {
    handleLogout();
  };
  const idleTimer = useIdleTimer({
    // crossTab:true,
    timeout: idleTimeout,
    promptBeforeIdle: 30000,
    onPrompt: onIdle,
    onIdle: handleIdle,
    disabled: disabled,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
  };
};
export default useIdleTimeout;
