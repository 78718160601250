import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import CssBaseline from "@mui/material/CssBaseline";
import ListItemText from "@mui/material/ListItemText";

type Anchor = "left";
type Props = {
  status: boolean;
  changeStatus: (st: boolean) => void;
};

export default function Drawer({ status, changeStatus }: Props) {
  const [state, setState] = useState({ left: status });

  useEffect(() => {
    setState({ left: status });
  }, [status]);

  useEffect(() => {
    changeStatus(state["left"]);
  }, [state,changeStatus]);

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "left" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      onClick={() => setState({ left: false })}
      onKeyDown={() => setState({ left: false })}
    >
      <List>
        {["SignIn", "SignUp"].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <React.Fragment key="left">
      <CssBaseline />
      <SwipeableDrawer
        anchor="left"
        open={state["left"]}
        onClose={() => setState({ left: false })}
        onOpen={() => setState({ left: true })}
      >
        {list("left")}
      </SwipeableDrawer>
    </React.Fragment>
  );
}
