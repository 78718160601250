import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// MUI

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Alert, Button, Card, FormControl, OutlinedInput } from "@mui/material";
import QRCode from "qrcode";
import { usePostQuery } from "../../../customHooks/reactQuery";
import AuthContext from "../../../context/AuthContext";
import { toast } from "react-toastify";
import NavbarProcess from "../../../compnents/navs/NavbarProcess";
import PageLayout from "../../Templates/PageLayout";
import Cookies from "js-cookie";

// COMPONENTS
import OtpInput from "react-otp-input";
import InputElement from "../../../compnents/2FAInputElement";

export default function TwoFactorAuthApp() {
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const ctxAuth = useContext(AuthContext);
  const navigate = useNavigate();

  const location = useLocation();
  const data = location.state;

  const {
    data: dataVerify,
    error: errorVerify,
    mutate: mutateOtpVerify,
    isSuccess: isSuccessVerify,
  } = usePostQuery("auth/otp/verify", ctxAuth.companyDetails.tokenAccess);

  const [formValues, setFormValues] = useState<any>({
    token: "",
  });
  const [otp, setOtp] = useState("");

  useEffect(() => {
    if (data?.data && data?.data?.otpauth_url)
      QRCode.toDataURL(data?.data?.otpauth_url).then(setqrCodeUrl);
  }, [data?.data]);

  useEffect(() => {
    if (isSuccessVerify && dataVerify?.data?.otp_verified) {
      toast.success("Token successfully verified");
      Cookies.set("otpValidated", "true");
      navigate("/security-settings");
    }
  }, [isSuccessVerify, dataVerify?.data?.otp_verified]);

  const handleInputChange = (otp: string) => {
    const value = otp.trim();

    setFormValues({
      ...formValues,
      token: value,
    });

    setOtp(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutateOtpVerify(formValues);
  };

  return (
    <PageLayout
      contentStyling={{ margin: "2rem" }}
      showBackgroundImage={false}
      CustomNavbar={<NavbarProcess />}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"70vh"}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            p: 4,
          }}
        >
          <Typography
            variant="h4"
            textAlign={"center"}
            fontWeight="bold"
            mb={2}
          >
            Setup Two Factor Authentication (2FA)
          </Typography>

          <Typography paragraph fontWeight="bold" align="center">
            Open the Google Authenticator app and scan this QR code
          </Typography>
          <img
            style={{ display: "block", margin: "auto" }}
            src={qrcodeUrl}
            alt="qrcode url"
          />
          <Typography paragraph align="center">
            Or enter the following code manually
          </Typography>
          <Typography
            fontWeight="bold"
            fontSize="16px"
            lineHeight="2"
            align="center"
          >
            {data?.data?.base32}
          </Typography>
          <Typography align="center" fontSize="16px" lineHeight="2">
            Enter the authentication code sent to your app and click verify to
            complete the setup process.
          </Typography>
          <form onSubmit={handleSubmit}>
            <FormControl variant="outlined" fullWidth>
              <OtpInput
                value={otp}
                onChange={handleInputChange}
                numInputs={6}
                containerStyle={{ justifyContent: "center" }}
                inputStyle={{
                  flex: 1,
                  border: "1px solid grey",
                }}
                renderSeparator={<span style={{ margin: ".2rem" }}> </span>}
                renderInput={({ ...props }) => (
                  <InputElement
                    required
                    sx={{ marginY: "20px" }}
                    {...props}
                    data-cy="token-input-field"
                  />
                )}
              />
            </FormControl>
            {errorVerify && (
              <Alert severity={"error"}>
                {errorVerify?.response?.data?.message}
              </Alert>
            )}
            <Button
              variant="contained"
              fullWidth
              type="submit"
              data-cy="app-verify-btn"
            >
              verify
            </Button>
          </form>
        </Card>
      </Box>
    </PageLayout>
  );
}
