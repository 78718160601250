import { main_url } from "../../constants";

import { useMutation } from "react-query";
import axios from "axios";
import { useContext } from "react";
import AuthContext from "../../context/AuthContext";

export const usePatchQuery = ({
  url,
  token = undefined,
  file = false,
  successFunction = () => {},
  errorFunction = () => {},
}: {
  url: string;
  token?: string;
  file?: boolean;
  successFunction?: (data: any) => void;
  errorFunction?: (data: any) => void;
}) => {
  const ctxAuth = useContext(AuthContext);
  const patchAxiosFunction = async (formData: any) => {
    //check if token is available
    if (!token) {
      return axios.patch(
        `${main_url}${url}`,
        file ? formData : JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
          timeout: 30000,
        }
      );
    }

    return axios.patch(
      `${main_url}${url}`,
      file ? formData : JSON.stringify(formData),
      {
        headers: token
          ? !file
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              }
            : { Authorization: `Bearer ${token}` }
          : { "Content-Type": "application/json" },

        timeout: 30000,
      }
    );
  };

  //   react query mutate function
  const createMutate = useMutation({
    mutationFn: patchAxiosFunction,
    onError: (err: any) => {
      return errorFunction(err);
    },
    onSuccess: (data: any) => {
      // const error = data.response.data;
      // //check if its a token validation error
      // if (error?.code === "token_not_valid") {
      //   console.log("Token not valid from fetch query");
      //   ctxAuth.logout();
      // } else {
      return successFunction(data);
      // }
    },
  });

  return createMutate;
};
