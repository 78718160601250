import { useState, useEffect, useMemo } from "react";

import { Box, Button, FormGroup, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { CompanyValues, ShareHolderInterface } from "../../interface/Common";
// RESOUCES
import Shareholders from "../../compnents/Shareholders";

type Props = {
  getShareHolders: (e: ShareHolderInterface) => void;
  getshareHolderIconStatus: (e: boolean) => void;
};

export default function ShareHolderDetails({
  getShareHolders,
  getshareHolderIconStatus,
}: Props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const sizeMatch = matches ? true : false;

  const [formValues, setFormValues] = useState<ShareHolderInterface>({
    shareholderObj: {},
  });

  const shareHolder = (e: any) => {
    setFormValues((prev: CompanyValues | any) => {
      return {
        ...prev,
        shareholderObj: { ...prev.shareholderObj, [e.htmlid]: e },
      };
    });
  };

  const [counterShareholder, changeCounterShareholder] = useState(1);

  const [holdernumber, setholdernumber] = useState([
    <Shareholders key={1} id={1} addShareHolder={shareHolder} />,
  ]);

  const unifiedCounter = (
    type: string,
    change: any,
    minItems: number,
    maxItems: number
  ) => {
    if (type === "sub") {
      change((prev: any) => {
        if (prev !== minItems) return --prev;
        return prev;
      });
    } else {
      change((prev: any) => {
        if (prev < maxItems) return ++prev;
        return prev;
      });
    }
  };

  const shareStake = useMemo(() => {
    let total = 0;
    for (const data in formValues.shareholderObj) {
      total += formValues.shareholderObj[data].shareholding;
    }
    return total;
  }, [formValues]);

  const shareholdingPerError = useMemo(() => {
    let response = false;
    Object.keys(formValues.shareholderObj).forEach((e: string) => {
      if (formValues.shareholderObj[e].shareholding < 10) {
        response = true;
      }
    });

    return response;
  }, [formValues.shareholderObj]);

  useEffect(() => {
    if (holdernumber.length < counterShareholder) {
      setholdernumber((prev) => [
        ...prev,
        <Shareholders
          key={holdernumber.length + 1}
          id={holdernumber.length + 1}
          addShareHolder={shareHolder}
        />,
      ]);
    } else {
      if (holdernumber.length > 1) {
        //Remove item from elements
        holdernumber.pop();
        setholdernumber([...holdernumber]);

        const deletedCounter = counterShareholder + 1;

        //Remove item from formValues
        if (deletedCounter in formValues.shareholderObj) {
          const data = formValues.shareholderObj;
          delete data[deletedCounter];
          setFormValues({
            ...formValues,
            shareholderObj: data,
          });
        }
      }
    }
  }, [counterShareholder]);

  const shareholdersArray = useMemo(
    () => holdernumber.map((e) => e),
    [holdernumber]
  );

  const shareholderIcon = useMemo(() => {
    //get length of shareholder object
    let shareHolderTruth = false;

    if (
      Object.keys(formValues.shareholderObj).length > 0 &&
      shareStake <= 100
    ) {
      Object.keys(formValues.shareholderObj).map((e: string) => {

        if (
          formValues.shareholderObj[e].name.length > 0 &&
          formValues.shareholderObj[e].d_o_b.length > 0 &&
          formValues.shareholderObj[e].shareholder_id.length > 0 &&
          formValues.shareholderObj[e].shareholding > 0 &&
          formValues.shareholderObj[e].shareholder_id_copy &&
          isNaN(Date.parse(formValues.shareholderObj[e].d_o_b)) === false
        ) {
          shareHolderTruth = true;
        } else {
          shareHolderTruth = false;
        }
      });
    }
    return shareHolderTruth;
  }, [formValues, shareStake]);

  useEffect(() => {
    getShareHolders(formValues);
    getshareHolderIconStatus(shareholderIcon);
  }, [shareholderIcon, formValues, getShareHolders, getshareHolderIconStatus]);

  return (
    <FormGroup row={sizeMatch}>
      <Typography mt={4} fontSize="16px" fontWeight="bold">
        ULTIMATE BENEFICIAL OWNERS - SHAREHOLDERS
      </Typography>
      <Typography mt={1} fontSize="14px" fontWeight="bold">
        The Ultimate Beneficial Owner is a person or entity who -directly or
        indirectly- ultimately owns or controls 10 % or more of the capital or
        voting rights of the company, or exercises by any other means a power of
        control over the company’s management, administration, or supervisory
        bodies. Please note that we might request you for additional documents
        based on the information shared.
      </Typography>

      {shareholdersArray}

      {shareStake > 100 ? (
        <Typography mt={2} mb={1} fontSize="14px" color={"error"}>
          Shareholding is currently over 100%. Please update the shareholding
          percentages appropriately.
        </Typography>
      ) : null}

      {shareholdingPerError ? (
        <Typography mt={2} mb={1} fontSize="14px" color={"error"}>
          There is a shareholder who's Shareholding is listed below 10%. Correct
          to proceed without errors.
        </Typography>
      ) : null}

      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Button
          variant="contained"
          sx={{ marginRight: "4px" }}
          onClick={() => unifiedCounter("add", changeCounterShareholder, 1, 10)}
          disabled={
            shareStake > 99 ||
            holdernumber.length === 10 ||
            shareholdingPerError
          }
        >
          Add Shareholder
        </Button>

        <Button
          variant="contained"
          sx={{ marginRight: "4px" }}
          onClick={() => unifiedCounter("sub", changeCounterShareholder, 1, 10)}
          disabled={holdernumber.length === 1}
        >
          Remove Shareholder
        </Button>
      </Box>
    </FormGroup>
  );
}
