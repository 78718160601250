import React, { useCallback, useState, useContext } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Drawer from "../Drawer";
import AuthContext from "../../context/AuthContext";

type Props = {
  noIcon?: boolean;
  exitLoginProcess?: boolean;
};

export default function NavbarProcess({
  noIcon,
  exitLoginProcess,
}: Props): JSX.Element {
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [openDrawer, setOpenDrawer] = useState(false);

  const changeUp = useCallback((data: boolean) => {
    setOpenDrawer(data);
  }, []);

  return (
    <React.Fragment>
      <CssBaseline />
      <Drawer status={openDrawer} changeStatus={changeUp} />

      <Box>
        <AppBar
          position="static"
          color="transparent"
          elevation={0}
          sx={{
            p: 1,
          }}
        >
          <Toolbar>
            <Typography
              variant="h5"
              sx={{ flexGrow: 1, cursor: "pointer" }}
              onClick={() => authCtx.homeNavigation()}
            >
              <Typography
                variant="inherit"
                display="inline"
                color="primary.main"
                sx={{ fontWeight: "bold" }}
              >
                BONTO
              </Typography>{" "}
            </Typography>

            {!noIcon && !exitLoginProcess ? (
              <IconButton
                sx={{ backgroundColor: "#CECEEC" }}
                onClick={() =>
                  authCtx.userDetails.isLoggedIn
                    ? authCtx.logout()
                    : navigate("/")
                }
                aria-label="close process"
              >
                <CloseIcon style={{ color: "black" }} />
              </IconButton>
            ) : null}

            {!noIcon && exitLoginProcess ? (
              <IconButton
                sx={{ backgroundColor: "#CECEEC" }}
                onClick={() => authCtx.logout()}
                aria-label="close process"
              >
                <CloseIcon style={{ color: "black" }} />
              </IconButton>
            ) : null}
          </Toolbar>
        </AppBar>
      </Box>
    </React.Fragment>
  );
}
