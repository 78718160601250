import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {
  BrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { AuthContextProvider } from "./context/AuthContext";
import { CompanyPDFContextProvider } from "./context/CompanyPDFContext";
import React from "react";
import { PaymentContextProvider } from "./context/PaymentContext";
import ScrollToTop from "./ScrollToTop";
import { theme } from "./theme/AppTheme";
import { ThemeProvider } from "@mui/material/styles";
import "./resources/css/base.css";

import { LocalizationContextProvider } from "./context/LocalizationContext";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "react-query";

const build_environment = process.env.REACT_APP_ENVIRONMENT;
const sentry_dsn =
  process.env.REACT_APP_SENTRY_DSN ||
  "https://c9a4e311c39c3e62c22a95df9e82adaa@o4504575217238016.ingest.us.sentry.io/4506943581454336";

// sentry configuration
Sentry.init({
  dsn: sentry_dsn,
  environment: build_environment,

  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),

    // new Sentry.Replay(),
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
    }),
  ],

  // Performance Monitoring
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  tracePropagationTargets: ["localhost", /^https:\/\/www\.bonto\.africa\//],

  // Session Replay
  replaysSessionSampleRate: build_environment === "development" ? 1.0 : 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.

  beforeSend: (event) => {
    console.log({ event });
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <CompanyPDFContextProvider>
              <PaymentContextProvider>
                <LocalizationContextProvider>
                  <App />
                </LocalizationContextProvider>
              </PaymentContextProvider>
            </CompanyPDFContextProvider>
          </AuthContextProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
