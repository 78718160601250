// MUI
import {
  Typography,
  Button,
  Box,
  FormControl,
  OutlinedInput,
  Alert,
  Card,
  IconButton,
  Tooltip,
} from "@mui/material";
// CUSTOM COMPONENTS
import InputElement from "../../../compnents/2FAInputElement";
// TEMPLATES
import PageLayout from "../../Templates/PageLayout";
import { Link, useNavigate } from "react-router-dom";
import NavbarProcess from "../../../compnents/navs/NavbarProcess";

import { useContext, useEffect, useMemo, useState } from "react";

// RESOUCES
import { usePost } from "../../../customHooks/reactQuery/usePost";
import AuthContext from "../../../context/AuthContext";
import { usePostQuery } from "../../../customHooks/reactQuery";
import { toast } from "react-toastify";

import PhonelinkLockIcon from "@mui/icons-material/PhonelinkLock";
import MailLockIcon from "@mui/icons-material/MailLock";
import {
  appMfaEnabled,
  emailMfaEnabled,
  mfaEnabled,
} from "../../../utils/userFunctions";

// OTP Library input field linrary
import OtpInput from "react-otp-input";

export default function ValidateOtp() {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const ctxAuth = useContext(AuthContext);

  const [preferredMethod, setPreferredMethod] = useState<string>(
    !appMfaEnabled(ctxAuth.userDetails) &&
      ctxAuth.userDetails.primary_2fa_method === "app"
      ? "email"
      : !emailMfaEnabled(ctxAuth.userDetails) &&
        ctxAuth.userDetails.primary_2fa_method === "email"
      ? "app"
      : ctxAuth.userDetails.primary_2fa_method
  );

  const {
    mutate: mutateGenerate,
    data: dataGenerateTokenEmail,
    isSuccess: isSuccessResend,
  } = usePostQuery(
    "auth/otp/generate-email",
    ctxAuth.companyDetails.tokenAccess
  );

  const { data, error, mutate, isSuccess } = usePost(
    "auth/otp/validate",
    ctxAuth.companyDetails.tokenAccess
  );
  const {
    data: dataEmail,
    mutate: mutateEmail,
    isSuccess: isSuccessEmail,
    error: errorEmailValidation,
  } = usePost("auth/otp/validate-email", ctxAuth.companyDetails.tokenAccess);

  const { mutate: mutateRecaptchaToken } = usePostQuery(
    "auth/validate-recaptcha",
    ctxAuth.companyDetails.tokenAccess
  );

  console.log(error);

  const [formValues, setFormValues] = useState<any>({
    token: "",
  });

  const [counter, setCounter] = useState<number>(
    Number(window.localStorage.getItem("counter")) > 0
      ? Number(window.localStorage.getItem("counter"))
      : 0
  );
  useMemo(() => {
    return error
      ? setCounter(error?.response?.data?.detail?.replace(/\D/g, ""))
      : setCounter(
          errorEmailValidation?.response?.data?.detail?.replace(/\D/g, "")
        );
  }, [error, errorEmailValidation]);

  useMemo(() => {
    if (counter > 0) {
      const timer = setTimeout(() => {
        window.localStorage.setItem("counter", String(counter - 1));
        return setCounter(counter - 1);
      }, 1000);

      return () => clearInterval(timer);
    }
    return;
  }, [counter]);

  useEffect(() => {
    const data = window.localStorage.getItem("counter");
    setCounter(Number(data));
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (data?.data?.user) {
        ctxAuth.loginHandler(data?.data?.user, data?.data?.otp_valid);
      }
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isSuccessEmail) {
      if (dataEmail?.data?.user) {
        ctxAuth.loginHandler(dataEmail?.data?.user, true);
      }
    }
  }, [isSuccessEmail, dataEmail]);

  useEffect(() => {
    if (!ctxAuth.userDetails.isLoggedIn && !ctxAuth.userDetails.email?.length)
      navigate("/sign-in");
  }, [ctxAuth]);

  const handleInputChange = (otp: string) => {
    const value = otp.trim();

    setFormValues({
      ...formValues,
      token: value,
    });

    setOtp(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    grecaptcha.ready(function () {
      grecaptcha
        .execute("6LfbTvcpAAAAAE1bBibTuxVQ-DJ3UKmIodSm3b2U", {
          action: `validation_submit_${preferredMethod}`,
        })
        .then(function (token) {
          return mutateRecaptchaToken({ token: token });
        });
    });

    preferredMethod === "app" ? mutate(formValues) : mutateEmail(formValues);
  };

  const generateEmailToken = () => {
    const formData = new FormData();
    mutateGenerate(formData);
  };

  const handleChangeToEmail = () => {
    generateEmailToken();
    setPreferredMethod("email");
  };

  useEffect(() => {
    if (isSuccessResend && dataGenerateTokenEmail) {
      toast.success("Token sent");
    }
  }, [isSuccess, dataGenerateTokenEmail]);

  return (
    <PageLayout
      contentStyling={{ margin: "2rem" }}
      showBackgroundImage={false}
      CustomNavbar={<NavbarProcess exitLoginProcess />}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minHeight={"70vh"}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "90%", md: "50%" },
            p: 4,
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl variant="outlined" fullWidth>
              <Typography
                variant="inherit"
                fontSize="30px"
                textAlign={"center"}
                fontWeight="bold"
                mb={2}
                data-cy="validate-otp-title"
              >
                User verification
              </Typography>
              {ctxAuth.userDetails.otp_enabled && preferredMethod === "app" ? (
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  data-cy="app-directions-text"
                  lineHeight={2}
                >
                  We use the two-step verification app to verify your identity.
                  Please enter the code from the app.
                </Typography>
              ) : (
                <Typography
                  variant="body1"
                  textAlign={"center"}
                  lineHeight={2}
                  data-cy="email-directions-text"
                >
                  We use email to verify your identity. Please enter the code
                  sent to your email address.
                </Typography>
              )}

              <OtpInput
                value={otp}
                onChange={handleInputChange}
                numInputs={6}
                containerStyle={{ justifyContent: "center" }}
                inputStyle={{
                  flex: 1,
                  border: "1px solid grey",
                }}
                renderSeparator={<span style={{ margin: ".2rem" }}> </span>}
                renderInput={({ ...props }) => (
                  <InputElement
                    required
                    sx={{ marginY: "20px" }}
                    {...props}
                    data-cy="token-input-field"
                  />
                )}
              />
            </FormControl>

            {counter > 0 ? (
              <Alert
                severity={"error"}
              >{`Too many incorrect attempts. Try again in ${counter} seconds`}</Alert>
            ) : error &&
              error?.message !== "Request failed with status code 429" ? (
              <Alert severity={"error"}>{error?.response?.data?.message}</Alert>
            ) : errorEmailValidation &&
              errorEmailValidation?.message !==
                "Request failed with status code 429" ? (
              <Alert data-cy="email-validate-err" severity={"error"}>
                {errorEmailValidation?.response?.data?.message}
              </Alert>
            ) : null}

            <Box display={"flex"} flexDirection={"row"} sx={{ gap: "5%" }}>
              <Button
                fullWidth
                variant="contained"
                type="submit"
                data-cy="validate-token-btn"
                disabled={counter > 0}
              >
                Authenticate
              </Button>

              {ctxAuth.userDetails.otp_email_enabled &&
              preferredMethod === "email" ? (
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => generateEmailToken()}
                  data-cy="use-email-btn"
                >
                  Resend Token
                </Button>
              ) : null}
            </Box>
          </form>
          <Box display={"flex"} justifyContent={"center"}>
            {mfaEnabled(ctxAuth.userDetails) &&
            emailMfaEnabled(ctxAuth.userDetails) &&
            preferredMethod === "app" ? (
              <Typography
                onClick={() => handleChangeToEmail()}
                style={{ cursor: "pointer" }}
                mt={2}
                data-cy="use-email-btn"
              >
                <Tooltip title="Use Email Authentication">
                  <IconButton aria-label="Email Authentication">
                    <MailLockIcon
                      data-cy="use-email-btn"
                      sx={{ color: "#425FB4" }}
                    />
                  </IconButton>
                </Tooltip>
                Use Email Authentication
              </Typography>
            ) : null}

            {mfaEnabled(ctxAuth.userDetails) &&
            appMfaEnabled(ctxAuth.userDetails) &&
            preferredMethod === "email" ? (
              <Typography
                onClick={() => setPreferredMethod("app")}
                style={{ cursor: "pointer" }}
                mt={2}
                data-cy="use-app-btn"
              >
                <Tooltip title="Use App Authentication">
                  <IconButton aria-label="App Authentication">
                    <PhonelinkLockIcon
                      data-cy="use-app-btn"
                      sx={{ color: "#425FB4" }}
                    />
                  </IconButton>
                </Tooltip>
                Use App Authentication
              </Typography>
            ) : null}
          </Box>

          <Typography
            variant="body1"
            textAlign={"center"}
            lineHeight={2}
            mt={2}
          >
            I need help?
            <Link
              to="/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#425FB4",
                textDecoration: "none",
              }}
            >
              {" "}
              contact us
            </Link>
          </Typography>
        </Card>
      </Box>
    </PageLayout>
  );
}
