import dayjs from "dayjs";
import {
  TCurrencyObject,
  currencies as TCurrencies,
} from "../interface/Common";

export const currencies: TCurrencies[] = ["USD", "KES", "EUR", "GBP"];

//export const regexName = /^[a-zA-ZÀ-ÿ]+(([',. -][a-zA-ZÀ-ÿ ])?[a-zA-ZÀ-ÿ]*)*$/;
export const regexName = /^(?!.*\s$)(?!.*[!@#$%^&*(),.?":{}|<>])(?!.*\d).+$/;

//export const regexNameNumbers = /^[a-zA-ZÀ-ÿ\d]+(([',. -][a-zA-ZÀ-ÿ\d ])?[a-zA-ZÀ-ÿ\d]*)*$/;
export const regexNameNumbers = /^(?!.*\s$)(?!.*[!@#$%^&*(),.?":{}|<>]).+$/;

export const currenciesNew: TCurrencyObject[] = [
  {
    value: "USD",
    label: "USD - US Dollar",
  },
  {
    value: "KES",
    label: "KES - Kenyan Shilling",
  },
  {
    value: "EUR",
    label: "EUR - Euros",
  },
  {
    value: "GBP",
    label: "GBP - British Pound",
  },
];

export const currenciesNewUpdate = [
  {
    currency_from: "USD",
    currency_to: "KES",
    label: "USD to KES",
  },
  {
    currency_from: "KES",
    currency_to: "USD",
    label: "KES to USD",
  },
  {
    currency_from: "EUR",
    currency_to: "KES",
    label: "EUR to KES",
  },
  {
    currency_from: "KES",
    currency_to: "EUR",
    label: "KES to EUR",
  },
  {
    currency_from: "GBP",
    currency_to: "KES",
    label: "GBP to KES",
  },
  {
    currency_from: "KES",
    currency_to: "GBP",
    label: "KES to GBP",
  },
];

export const main_url = process.env.REACT_APP_API_URL;

export const currency = function (money: number, code: string) {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: code,
    minimumFractionDigits: 2,
    currencyDisplay: "code",
  }).format(money);
};

export const formatAsPercentage = (num: number) => {
  return new Intl.NumberFormat("default", {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(num / 100);
};

export const employeesRange = [
  "0-10",
  "11-100",
  "101-1000",
  "1001-10,000",
  "Over 10,000",
];

export const dateComparison = (firstDate: Date, secondDate: Date): number => {
  if (dayjs(firstDate)?.isAfter(dayjs(secondDate))) return 1;
  if (dayjs(firstDate)?.isBefore(dayjs(secondDate))) return -1;
  return 0;
};

export const minmumBirthDate = () => {
  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setTime(
    eighteenYearsAgo.valueOf() - 18 * 365 * 24 * 60 * 60 * 1000
  );
  return `${eighteenYearsAgo.getFullYear()}-${
    eighteenYearsAgo.getMonth() + 1 < 10 ? "0" : ""
  }${eighteenYearsAgo.getMonth() + 1}-${
    eighteenYearsAgo.getDate() < 10 ? "0" : ""
  }${eighteenYearsAgo.getDate()}`;
};

export const COUNTRIES = [
  {
    name: "Select Country(s)",
    code: " ",
  },
  {
    name: "Afghanistan",
    code: "AFG",
    flag: "AF",
    phone: "93",
  },
  {
    name: "Ålland Islands",
    code: "ALA",
    flag: "AX",
    phone: "358",
  },
  {
    name: "Albania",
    code: "ALB",
    flag: "AL",
    phone: "355",
  },
  {
    name: "Algeria",
    code: "DZA",
    flag: "DZ",
    phone: "213",
  },
  {
    name: "American Samoa",
    code: "ASM",
    flag: "AS",
    phone: "1-684",
  },
  {
    name: "Andorra",
    code: "AND",
    flag: "AD",
    phone: "376",
  },
  {
    name: "Angola",
    code: "AGO",
    flag: "AO",
    phone: "244",
  },
  {
    name: "Anguilla",
    code: "AIA",
    flag: "AI",
    phone: "1-264",
  },
  {
    name: "Antarctica",
    code: "ATA",
    flag: "AQ",
    phone: "672",
  },
  {
    name: "Antigua and Barbuda",
    code: "ATG",
    flag: "AG",
    phone: "1-268",
  },
  {
    name: "Argentina",
    code: "ARG",
    flag: "AR",
    phone: "54",
  },
  {
    name: "Armenia",
    code: "ARM",
    flag: "AM",
    phone: "374",
  },
  {
    name: "Aruba",
    code: "ABW",
    flag: "AW",
    phone: "297",
  },
  {
    name: "Australia",
    code: "AUS",
    flag: "AU",
    phone: "61",
  },
  {
    name: "Austria",
    code: "AUT",
    flag: "AT",
    phone: "43",
  },
  {
    name: "Azerbaijan",
    code: "AZE",
    flag: "AZ",
    phone: "994",
  },
  {
    name: "Bahamas",
    code: "BHS",
    flag: "BS",
    phone: "1-242",
  },
  {
    name: "Bahrain",
    code: "BHR",
    flag: "BH",
    phone: "973",
  },
  {
    name: "Bangladesh",
    code: "BGD",
    flag: "BD",
    phone: "880",
  },
  {
    name: "Barbados",
    code: "BRB",
    flag: "BB",
    phone: "1-246",
  },
  {
    name: "Belarus",
    code: "BLR",
    flag: "BY",
    phone: "375",
  },
  {
    name: "Belgium",
    code: "BEL",
    flag: "BE",
    phone: "32",
  },
  {
    name: "Belize",
    code: "BLZ",
    flag: "BZ",
    phone: "501",
  },
  {
    name: "Benin",
    code: "BEN",
    flag: "BJ",
    phone: "229",
  },
  {
    name: "Bermuda",
    code: "BMU",
    flag: "BM",
    phone: "1-441",
  },
  {
    name: "Bhutan",
    code: "BTN",
    flag: "BT",
    phone: "975",
  },
  {
    name: "Bolivia",
    code: "BOL",
    flag: "BO",
    phone: "591",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BES",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BIH",
    flag: "BA",
    phone: "387",
  },
  {
    name: "Botswana",
    code: "BWA",
    flag: "BW",
    phone: "267",
  },
  {
    name: "Bouvet Island",
    code: "BVT",
    flag: "BV",
    phone: "47",
  },
  {
    name: "Brazil",
    code: "BRA",
    flag: "BR",
    phone: "55",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IOT",
    flag: "IO",
    phone: "246",
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UMI",
  },
  {
    name: "Virgin Islands (British)",
    code: "VGB",
    flag: "VG",
    phone: "1-284",
  },
  {
    name: "Virgin Islands (U.S.)",
    code: "VIR",
    flag: "VI",
    phone: "1-340",
  },
  {
    name: "Brunei Darussalam",
    code: "BRN",
    flag: "BN",
    phone: "673",
  },
  {
    name: "Bulgaria",
    code: "BGR",
    flag: "BG",
    phone: "359",
  },
  {
    name: "Burkina Faso",
    code: "BFA",
    flag: "BF",
    phone: "226",
  },
  {
    name: "Burundi",
    code: "BDI",
    flag: "BI",
    phone: "257",
  },
  {
    name: "Cambodia",
    code: "KHM",
    flag: "KH",
    phone: "855",
  },
  {
    name: "Cameroon",
    code: "CMR",
    flag: "CM",
    phone: "237",
  },
  {
    name: "Canada",
    code: "CAN",
    flag: "CA",
    phone: "1",
  },
  {
    name: "Cabo Verde",
    code: "CPV",
    flag: "CV",
    phone: "238",
  },
  {
    name: "Cayman Islands",
    code: "CYM",
    flag: "KY",
    phone: "1-345",
  },
  {
    name: "Central African Republic",
    code: "CAF",
    flag: "CF",
    phone: "236",
  },
  {
    name: "Chad",
    code: "TCD",
    flag: "TD",
    phone: "235",
  },
  {
    name: "Chile",
    code: "CHL",
    flag: "CL",
    phone: "56",
  },
  {
    name: "China",
    code: "CHN",
    flag: "CN",
    phone: "86",
  },
  {
    name: "Christmas Island",
    code: "CXR",
    flag: "CX",
    phone: "61",
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CCK",
    flag: "CC",
    phone: "61",
  },
  {
    name: "Colombia",
    code: "COL",
    flag: "CO",
    phone: "57",
  },
  {
    name: "Comoros",
    code: "COM",
    flag: "KM",
    phone: "269",
  },
  {
    name: "Congo",
    code: "COG",
    flag: "CG",
    phone: "242",
  },
  {
    name: "Congo, Democratic Republic of the",
    code: "COD",
    flag: "CD",
    phone: "243",
  },
  {
    name: "Cook Islands",
    code: "COK",
    flag: "CK",
    phone: "682",
  },
  {
    name: "Costa Rica",
    code: "CRI",
    flag: "CR",
    phone: "506",
  },
  {
    name: "Croatia",
    code: "HRV",
    flag: "HR",
    phone: "385",
  },
  {
    name: "Cuba",
    code: "CUB",
    flag: "CU",
    phone: "53",
  },
  {
    name: "Curaçao",
    code: "CUW",
    flag: "CW",
    phone: "599",
  },
  {
    name: "Cyprus",
    code: "CYP",
    flag: "CY",
    phone: "357",
  },
  {
    name: "Czech Republic",
    code: "CZE",
    flag: "CZ",
    phone: "420",
  },
  {
    name: "Denmark",
    code: "DNK",
    flag: "DK",
    phone: "45",
  },
  {
    name: "Djibouti",
    code: "DJI",
    flag: "DJ",
    phone: "253",
  },
  {
    name: "Dominica",
    code: "DMA",
    flag: "DM",
    phone: "1-767",
  },
  {
    name: "Dominican Republic",
    code: "DOM",
    flag: "DO",
    phone: "1-809",
  },
  {
    name: "Ecuador",
    code: "ECU",
    flag: "EC",
    phone: "593",
  },
  {
    name: "Egypt",
    code: "EGY",
    flag: "EG",
    phone: "20",
  },
  {
    name: "El Salvador",
    code: "SLV",
    flag: "SV",
    phone: "503",
  },
  {
    name: "Equatorial Guinea",
    code: "GNQ",
    flag: "GQ",
    phone: "240",
  },
  {
    name: "Eritrea",
    code: "ERI",
    flag: "ER",
    phone: "291",
  },
  {
    name: "Estonia",
    code: "EST",
    flag: "EE",
    phone: "372",
  },
  {
    name: "Ethiopia",
    code: "ETH",
    flag: "ET",
    phone: "251",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FLK",
    flag: "FK",
    phone: "500",
  },
  {
    name: "Faroe Islands",
    code: "FRO",
    flag: "FO",
    phone: "298",
  },
  {
    name: "Fiji",
    code: "FJI",
    flag: "FJ",
    phone: "679",
  },
  {
    name: "Finland",
    code: "FIN",
    flag: "FI",
    phone: "358",
  },
  {
    name: "France",
    code: "FRA",
    flag: "FR",
    phone: "33",
  },
  {
    name: "French Guiana",
    code: "GUF",
    flag: "GF",
    phone: "594",
  },
  {
    name: "French Polynesia",
    code: "PYF",
    flag: "PF",
    phone: "689",
  },
  {
    name: "French Southern Territories",
    code: "ATF",
    flag: "TF",
    phone: "262",
  },
  {
    name: "Gabon",
    code: "GAB",
    flag: "GA",
    phone: "241",
  },
  {
    name: "Gambia",
    code: "GMB",
    flag: "GM",
    phone: "220",
  },
  {
    name: "Georgia",
    code: "GEO",
    flag: "GE",
    phone: "995",
  },
  {
    name: "Germany",
    code: "DEU",
    flag: "DE",
    phone: "49",
  },
  {
    name: "Ghana",
    code: "GHA",
    flag: "GH",
    phone: "233",
  },
  {
    name: "Gibraltar",
    code: "GIB",
    flag: "GI",
    phone: "350",
  },
  {
    name: "Greece",
    code: "GRC",
    flag: "GR",
    phone: "30",
  },
  {
    name: "Greenland",
    code: "GRL",
    flag: "GL",
    phone: "299",
  },
  {
    name: "Grenada",
    code: "GRD",
    flag: "GD",
    phone: "1-473",
  },
  {
    name: "Guadeloupe",
    code: "GLP",
    flag: "GP",
    phone: "590",
  },
  {
    name: "Guam",
    code: "GUM",
    flag: "GU",
    phone: "1-671",
  },
  {
    name: "Guatemala",
    code: "GTM",
    flag: "GT",
    phone: "502",
  },
  {
    name: "Guernsey",
    code: "GGY",
    flag: "GG",
    phone: "44",
  },
  {
    name: "Guinea",
    code: "GIN",
    flag: "GN",
    phone: "224",
  },
  {
    name: "Guinea-Bissau",
    code: "GNB",
    flag: "GW",
    phone: "245",
  },
  {
    name: "Guyana",
    code: "GUY",
    flag: "GY",
    phone: "592",
  },
  {
    name: "Haiti",
    code: "HTI",
    flag: "HT",
    phone: "509",
  },
  {
    name: "Heard Island and McDonald Islands",
    code: "HMD",
    flag: "HM",
    phone: "672",
  },
  {
    name: "Vatican City",
    code: "VAT",
  },
  {
    name: "Honduras",
    code: "HND",
    flag: "HN",
    phone: "504",
  },
  {
    name: "Hungary",
    code: "HUN",
    flag: "HU",
    phone: "36",
  },
  {
    name: "Hong Kong",
    code: "HKG",
    flag: "HK",
    phone: "852",
  },
  {
    name: "Iceland",
    code: "ISL",
    flag: "IS",
    phone: "354",
  },
  {
    name: "India",
    code: "IND",
    flag: "IN",
    phone: "91",
  },
  {
    name: "Indonesia",
    code: "IDN",
    flag: "ID",
    phone: "62",
  },
  {
    name: "Ivory Coast",
    code: "CIV",
  },
  {
    name: "Iran (Islamic Republic of)",
    code: "IRN",
    flag: "IR",
    phone: "98",
  },
  {
    name: "Iraq",
    code: "IRQ",
    flag: "IQ",
    phone: "964",
  },
  {
    name: "Ireland",
    code: "IRL",
    flag: "IE",
    phone: "353",
  },
  {
    name: "Isle of Man",
    code: "IMN",
    flag: "IM",
    phone: "44",
  },
  {
    name: "Israel",
    code: "ISR",
    flag: "IL",
    phone: "972",
  },
  {
    name: "Italy",
    code: "ITA",
    flag: "IT",
    phone: "39",
  },
  {
    name: "Jamaica",
    code: "JAM",
    flag: "JM",
    phone: "1-876",
  },
  {
    name: "Japan",
    code: "JPN",
    flag: "JP",
    phone: "81",
  },
  {
    name: "Jersey",
    code: "JEY",
    flag: "JE",
    phone: "44",
  },
  {
    name: "Jordan",
    code: "JOR",
    flag: "JO",
    phone: "962",
  },
  {
    name: "Kazakhstan",
    code: "KAZ",
    flag: "KZ",
    phone: "7",
  },
  {
    name: "Kenya",
    code: "KEN",
    flag: "KE",
    phone: "254",
  },
  {
    name: "Kiribati",
    code: "KIR",
    flag: "KI",
    phone: "686",
  },
  {
    name: "Kuwait",
    code: "KWT",
    flag: "KW",
    phone: "965",
  },
  {
    name: "Kyrgyzstan",
    code: "KGZ",
    flag: "KG",
    phone: "996",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LAO",
    flag: "LA",
    phone: "856",
  },
  {
    name: "Latvia",
    code: "LVA",
    flag: "LV",
    phone: "371",
  },
  {
    name: "Lebanon",
    code: "LBN",
    flag: "LB",
    phone: "961",
  },
  {
    name: "Lesotho",
    code: "LSO",
    flag: "LS",
    phone: "266",
  },
  {
    name: "Liberia",
    code: "LBR",
    flag: "LR",
    phone: "231",
  },
  {
    name: "Libya",
    code: "LBY",
    flag: "LY",
    phone: "218",
  },
  {
    name: "Liechtenstein",
    code: "LIE",
    flag: "LI",
    phone: "423",
  },
  {
    name: "Lithuania",
    code: "LTU",
    flag: "LT",
    phone: "370",
  },
  {
    name: "Luxembourg",
    code: "LUX",
    flag: "LU",
    phone: "352",
  },
  {
    name: "Macao",
    code: "MAC",
    flag: "MO",
    phone: "853",
  },
  {
    name: "North Macedonia",
    code: "MKD",
    flag: "MK",
    phone: "389",
  },
  {
    name: "Madagascar",
    code: "MDG",
    flag: "MG",
    phone: "261",
  },
  {
    name: "Malawi",
    code: "MWI",
    flag: "MW",
    phone: "265",
  },
  {
    name: "Malaysia",
    code: "MYS",
    flag: "MY",
    phone: "60",
  },
  {
    name: "Maldives",
    code: "MDV",
    flag: "MV",
    phone: "960",
  },
  {
    name: "Mali",
    code: "MLI",
    flag: "ML",
    phone: "223",
  },
  {
    name: "Malta",
    code: "MLT",
    flag: "MT",
    phone: "356",
  },
  {
    name: "Marshall Islands",
    code: "MHL",
    flag: "MH",
    phone: "692",
  },
  {
    name: "Martinique",
    code: "MTQ",
    flag: "MQ",
    phone: "596",
  },
  {
    name: "Mauritania",
    code: "MRT",
    flag: "MR",
    phone: "222",
  },
  {
    name: "Mauritius",
    code: "MUS",
    flag: "MU",
    phone: "230",
  },
  {
    name: "Mayotte",
    code: "MYT",
    flag: "YT",
    phone: "262",
  },
  {
    name: "Mexico",
    code: "MEX",
    flag: "MX",
    phone: "52",
  },
  {
    name: "Micronesia (Federated States of)",
    code: "FSM",
    flag: "FM",
    phone: "691",
  },
  {
    name: "Moldova (Republic of)",
    code: "MDA",
    flag: "MD",
    phone: "373",
  },
  {
    name: "Monaco",
    code: "MCO",
    flag: "MC",
    phone: "377",
  },
  {
    name: "Mongolia",
    code: "MNG",
    flag: "MN",
    phone: "976",
  },
  {
    name: "Montenegro",
    code: "MNE",
    flag: "ME",
    phone: "382",
  },
  {
    name: "Montserrat",
    code: "MSR",
    flag: "MS",
    phone: "1-664",
  },
  {
    name: "Morocco",
    code: "MAR",
    flag: "MA",
    phone: "212",
  },
  {
    name: "Mozambique",
    code: "MOZ",
    flag: "MZ",
    phone: "258",
  },
  {
    name: "Myanmar",
    code: "MMR",
    flag: "MM",
    phone: "95",
  },
  {
    name: "Namibia",
    code: "NAM",
    flag: "NA",
    phone: "264",
  },
  {
    name: "Nauru",
    code: "NRU",
    flag: "NR",
    phone: "674",
  },
  {
    name: "Nepal",
    code: "NPL",
    flag: "NP",
    phone: "977",
  },
  {
    name: "Netherlands",
    code: "NLD",
    flag: "NL",
    phone: "31",
  },
  {
    name: "New Caledonia",
    code: "NCL",
    flag: "NC",
    phone: "687",
  },
  {
    name: "New Zealand",
    code: "NZL",
    flag: "NZ",
    phone: "64",
  },
  {
    name: "Nicaragua",
    code: "NIC",
    flag: "NI",
    phone: "505",
  },
  {
    name: "Niger",
    code: "NER",
    flag: "NE",
    phone: "227",
  },
  {
    name: "Nigeria",
    code: "NGA",
    flag: "NG",
    phone: "234",
  },
  {
    name: "Niue",
    code: "NIU",
    flag: "NU",
    phone: "683",
  },
  {
    name: "Norfolk Island",
    code: "NFK",
    flag: "NF",
    phone: "672",
  },
  {
    name: "Korea (Democratic People's Republic of)",
    code: "PRK",
    flag: "KP",
    phone: "850",
  },
  {
    name: "Northern Mariana Islands",
    code: "MNP",
    flag: "MP",
    phone: "1-670",
  },
  {
    name: "Norway",
    code: "NOR",
    flag: "NO",
    phone: "47",
  },
  {
    name: "Oman",
    code: "OMN",
    flag: "OM",
    phone: "968",
  },
  {
    name: "Pakistan",
    code: "PAK",
    flag: "PK",
    phone: "92",
  },
  {
    name: "Palau",
    code: "PLW",
    flag: "PW",
    phone: "680",
  },
  {
    name: "Palestine, State of",
    code: "PSE",
    flag: "PS",
    phone: "970",
  },
  {
    name: "Panama",
    code: "PAN",
    flag: "PA",
    phone: "507",
  },
  {
    name: "Papua New Guinea",
    code: "PNG",
    flag: "PG",
    phone: "675",
  },
  {
    name: "Paraguay",
    code: "PRY",
    flag: "PY",
    phone: "595",
  },
  {
    name: "Peru",
    code: "PER",
    flag: "PE",
    phone: "51",
  },
  {
    name: "Philippines",
    code: "PHL",
    flag: "PH",
    phone: "63",
  },
  {
    name: "Pitcairn",
    code: "PCN",
    flag: "PN",
    phone: "870",
  },
  {
    name: "Poland",
    code: "POL",
    flag: "PL",
    phone: "48",
  },
  {
    name: "Portugal",
    code: "PRT",
    flag: "PT",
    phone: "351",
  },
  {
    name: "Puerto Rico",
    code: "PRI",
    flag: "PR",
    phone: "1",
  },
  {
    name: "Qatar",
    code: "QAT",
    flag: "QA",
    phone: "974",
  },
  {
    name: "Republic of Kosovo",
    code: "UNK",
    flag: "XK",
    phone: "383",
  },
  {
    name: "Réunion",
    code: "REU",
    flag: "RE",
    phone: "262",
  },
  {
    name: "Romania",
    code: "ROU",
    flag: "RO",
    phone: "40",
  },
  {
    name: "Russian Federation",
    code: "RUS",
    flag: "RU",
    phone: "7",
  },
  {
    name: "Rwanda",
    code: "RWA",
    flag: "RW",
    phone: "250",
  },
  {
    name: "Saint Barthélemy",
    code: "BLM",
    flag: "BL",
    phone: "590",
  },
  {
    name: "Saint Helena, Ascension and Tristan da Cunha",
    code: "SHN",
    flag: "SH",
    phone: "290",
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KNA",
    flag: "KN",
    phone: "1-869",
  },
  {
    name: "Saint Lucia",
    code: "LCA",
    flag: "LC",
    phone: "1-758",
  },
  {
    name: "Saint Martin (French part)",
    code: "MAF",
    flag: "MF",
    phone: "590",
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "SPM",
    flag: "PM",
    phone: "508",
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VCT",
    flag: "VC",
    phone: "1-784",
  },
  {
    name: "Samoa",
    code: "WSM",
    flag: "WS",
    phone: "685",
  },
  {
    name: "San Marino",
    code: "SMR",
    flag: "SM",
    phone: "378",
  },
  {
    name: "Sao Tome and Principe",
    code: "STP",
    flag: "ST",
    phone: "239",
  },
  {
    name: "Saudi Arabia",
    code: "SAU",
    flag: "SA",
    phone: "966",
  },
  {
    name: "Senegal",
    code: "SEN",
    flag: "SN",
    phone: "221",
  },
  {
    name: "Serbia",
    code: "SRB",
    flag: "RS",
    phone: "381",
  },
  {
    name: "Seychelles",
    code: "SYC",
    flag: "SC",
    phone: "248",
  },
  {
    name: "Sierra Leone",
    code: "SLE",
    flag: "SL",
    phone: "232",
  },
  {
    name: "Singapore",
    code: "SGP",
    flag: "SG",
    phone: "65",
  },
  {
    name: "Sint Maarten (Dutch part)",
    code: "SXM",
    flag: "SX",
    phone: "1-721",
  },
  {
    name: "Slovakia",
    code: "SVK",
    flag: "SK",
    phone: "421",
  },
  {
    name: "Slovenia",
    code: "SVN",
    flag: "SI",
    phone: "386",
  },
  {
    name: "Solomon Islands",
    code: "SLB",
    flag: "SB",
    phone: "677",
  },
  {
    name: "Somalia",
    code: "SOM",
    flag: "SO",
    phone: "252",
  },
  {
    name: "South Africa",
    code: "ZAF",
    flag: "ZA",
    phone: "27",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "SGS",
    flag: "GS",
    phone: "500",
  },
  {
    name: "Korea (Republic of)",
    code: "KOR",
    flag: "KR",
    phone: "82",
  },
  {
    name: "Spain",
    code: "ESP",
    flag: "ES",
    phone: "34",
  },
  {
    name: "Sri Lanka",
    code: "LKA",
    flag: "LK",
    phone: "94",
  },
  {
    name: "Sudan",
    code: "SDN",
    flag: "SD",
    phone: "249",
  },
  {
    name: "South Sudan",
    code: "SSD",
    flag: "SS",
    phone: "211",
  },
  {
    name: "Suriname",
    code: "SUR",
    flag: "SR",
    phone: "597",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJM",
    flag: "SJ",
    phone: "47",
  },
  {
    name: "Swaziland",
    code: "SWZ",
    flag: "SZ",
    phone: "268",
  },
  {
    name: "Sweden",
    code: "SWE",
    flag: "SE",
    phone: "46",
  },
  {
    name: "Switzerland",
    code: "CHE",
    flag: "CH",
    phone: "41",
  },
  {
    name: "Syrian Arab Republic",
    code: "SYR",
    flag: "SY",
    phone: "963",
  },
  {
    name: "Taiwan",
    code: "TWN",
    flag: "TW",
    phone: "886",
  },
  {
    name: "Tajikistan",
    code: "TJK",
    flag: "TJ",
    phone: "992",
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZA",
    flag: "TZ",
    phone: "255",
  },
  {
    name: "Thailand",
    code: "THA",
    flag: "TH",
    phone: "66",
  },
  {
    name: "Timor-Leste",
    code: "TLS",
    flag: "TL",
    phone: "670",
  },
  {
    name: "Togo",
    code: "TGO",
    flag: "TG",
    phone: "228",
  },
  {
    name: "Tokelau",
    code: "TKL",
    flag: "TK",
    phone: "690",
  },
  {
    name: "Tonga",
    code: "TON",
    flag: "TO",
    phone: "676",
  },
  {
    name: "Trinidad and Tobago",
    code: "TTO",
    flag: "TT",
    phone: "1-868",
  },
  {
    name: "Tunisia",
    code: "TUN",
    flag: "TN",
    phone: "216",
  },
  {
    name: "Turkey",
    code: "TUR",
    flag: "TR",
    phone: "90",
  },
  {
    name: "Turkmenistan",
    code: "TKM",
    flag: "TM",
    phone: "993",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TCA",
    flag: "TC",
    phone: "1-649",
  },
  {
    name: "Tuvalu",
    code: "TUV",
    flag: "TV",
    phone: "688",
  },
  {
    name: "Uganda",
    code: "UGA",
    flag: "UG",
    phone: "256",
  },
  {
    name: "Ukraine",
    code: "UKR",
    flag: "UA",
    phone: "380",
  },
  {
    name: "United Arab Emirates",
    code: "ARE",
    flag: "AE",
    phone: "971",
  },
  {
    name: "United Kingdom of Great Britain and Northern Ireland",
    code: "GBR",
    flag: "GB",
    phone: "44",
  },
  {
    name: "United States of America",
    code: "USA",
    flag: "US",
    phone: "1",
  },
  {
    name: "Uruguay",
    code: "URY",
    flag: "UY",
    phone: "598",
  },
  {
    name: "Uzbekistan",
    code: "UZB",
    flag: "UZ",
    phone: "998",
  },
  {
    name: "Vanuatu",
    code: "VUT",
    flag: "VU",
    phone: "678",
  },
  {
    name: "Venezuela (Bolivarian Republic of)",
    code: "VEN",
    flag: "VE",
    phone: "58",
  },
  {
    name: "Vietnam",
    code: "VNM",
    flag: "VN",
    phone: "84",
  },
  {
    name: "Wallis and Futuna",
    code: "WLF",
    flag: "WF",
    phone: "681",
  },
  {
    name: "Western Sahara",
    code: "ESH",
    flag: "EH",
    phone: "212",
  },
  {
    name: "Yemen",
    code: "YEM",
    flag: "YE",
    phone: "967",
  },
  {
    name: "Zambia",
    code: "ZMB",
    flag: "ZM",
    phone: "260",
  },
  {
    name: "Zimbabwe",
    code: "ZWE",
    flag: "ZW",
    phone: "263",
  },
];
