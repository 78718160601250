import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IIdleTimer } from "react-idle-timer";

// MUI
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import DialogTitle from "@mui/material/DialogTitle";
import { Button, DialogActions } from "@mui/material";

interface IdleTimeoutDialogProps {
  stayLoggedIn: () => void;
  logout: () => void;
  open: boolean;
  idleTimer: IIdleTimer;
}
export default function IdleTimeoutDialog(props: IdleTimeoutDialogProps) {
  const { stayLoggedIn, logout, open, idleTimer } = props;

  const [remainingTime, setRemainingTime] = useState(()=>Math.ceil(idleTimer.getRemainingTime() / 1000));

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(idleTimer.getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  return (
    <Dialog
      open={open}
      keepMounted
      aria-describedby="idle-timeout"
      maxWidth="sm"
      fullWidth
      PaperProps={{
        style: { borderRadius: "20px", backgroundColor: "#ffffff" },
      }}
    >
      <Box sx={{ p: 4 }}>
        <DialogTitle>
          <Typography variant="inherit" fontSize="30px" fontWeight="bold">
            Your session is about to expire
          </Typography>
        </DialogTitle>
        <Divider variant="middle" />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Your session is about to expire in{" "}
            {remainingTime} seconds. You will be
            automatically signed out.
            <br />
            <br />
            <b>Do you want to stay signed in?</b>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button variant="contained" fullWidth onClick={() => stayLoggedIn()}>
            Stay Signed in
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              logout();
            }}
          >
            Sign out
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
