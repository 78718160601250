import { useContext } from "react";
import { Navigate, Outlet, useOutlet } from "react-router-dom";
import AuthContext from "../../../context/AuthContext";
import CompanyPDFContext from "../../../context/CompanyPDFContext";

export default function CompanyCreation() {
  const ctxAuth = useContext(AuthContext);
  const companyDetails = useContext(CompanyPDFContext);

  const outlet = useOutlet();

  let path = outlet?.props?.children?.props?.match?.pathname;

  if (ctxAuth.userDetails.hasCompany) {
    return <Navigate to="/company-document" replace={true} />;
  }

  // if (path === "/company-document" && companyDetails.companyDetails.id === 0) {
  //   return <Navigate to="/company-setup" replace={true} />;
  // }

  // if (path === "company-setup" && companyDetails.companyDetails.id !== 0) {
  //   return <Navigate to="/company-document" replace={true} />;
  // }

  return <Outlet />;
}
