import { useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import AuthContext, {
  CompanyDetails,
  UserDetails,
} from "../../context/AuthContext";
import { Cookies } from "react-cookie-consent";
import { useFetchQuery } from "../../customHooks/reactQuery/useFetchQuery";
import { ClearUserDetails } from "./utils";

function OpenRoutes() {
  const ctxAuth = useContext(AuthContext);

  const { data, refetch, isError, error, isSuccess } = useFetchQuery(
    "token/validate-access-token/",
    "token-validation",
    false,
    false,
    Cookies.get("tokenAccess")
  );

  useEffect(() => {
    if (!ctxAuth.userDetails.isLoggedIn) {
      if (isSuccess) {
        if (data?.email.length > 0) {
          const backDetails: CompanyDetails = {
            companyName: data.company_name as string,
            tokenAccess: Cookies.get("tokenAccess") as string,
            tokenRefresh: Cookies.get("tokenAccess") as string,
            referenceNumber: data.reference_number as string,
            company_kyc_form_signed: data.company_kyc_form_signed as boolean,
            isBannedCompany: data.company_banned as boolean,
            documentsVerified: data.documents_verified as boolean,
          };

          const userDetails: UserDetails = {
            email: data.email as string,
            name: data.name as string,
            isLoggedIn: true,
            hasCompany: data.has_company as boolean,
            isMainUser: data.is_main_user as boolean,
            otp_enabled: data.otp_enabled as boolean,
            otp_verified: data.otp_verified as boolean,
            otp_validated: data.otp_validated as boolean,
            otp_email_enabled: data.otp_email_enabled as boolean,
            otp_email_verified: data.otp_email_verified as boolean,
            primary_2fa_method: data.primary_2fa_method as string,
          };

          ctxAuth.setCompany(backDetails);
          ctxAuth.setUser(userDetails);
        } else {
          ClearUserDetails(ctxAuth);
        }
      } else if (isError) {
        ClearUserDetails(ctxAuth);
      } else {
        refetch();
      }
    } else {
      if (Cookies.get("tokenAccess") === undefined) {
        ClearUserDetails(ctxAuth);
        ctxAuth.logout();
      }
    }
  }, [
    data,
    error,
    isError,
    isSuccess,
    ctxAuth.userDetails.isLoggedIn,
    Cookies.get("tokenAccess"),
  ]);

  return <Outlet />;
}

export default OpenRoutes;
