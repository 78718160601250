import { useMemo } from "react";
import { Box, Typography, OutlinedInput, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import FileUploadComponent from "./FileUpload";
import { regexName } from "../constants";

type Props = {
  id: number;
  addDirector: (e: any) => any;
};

interface DirectorsName {
  [fname: string]: string;
}

interface DirectorsMiddleName {
  [mname: string]: string;
}

interface DirectorsLastName {
  [lname: string]: string;
}

interface Directorsid {
  [Dirid: string]: File | null;
}

export default function Directors({ id, addDirector }: Props) {
  const [fName, setFname] = useState<DirectorsName>();
  const [mName, setMname] = useState<DirectorsMiddleName>();
  const [lName, setLname] = useState<DirectorsLastName>();
  const [dir, setDir] = useState<Directorsid>();

  const dirIdAdd = (e: any) => {
    setDir(e);
  };

  const setName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name.includes("first_name_")) {
      setFname({ [name]: value });
    } else if (name.includes("middle_name_")) {
      setMname({ [name]: value });
    } else {
      setLname({ [name]: value });
    }
  };

  useEffect(() => {
    addDirector({ fName, mName, lName, dir, id });
  }, [fName, mName, lName, dir, id, addDirector]);

  const irregularText = useMemo(() => {
    let regbool = false;

    if (mName && mName[Object.keys(mName)[0]].length > 0) {
      regbool =
        regexName.test(`${fName && fName[Object.keys(fName)[0]]}`) &&
        regexName.test(`${mName && mName[Object.keys(mName)[0]]}`) &&
        regexName.test(`${lName && lName[Object.keys(lName)[0]]}`);
    } else {
      regbool =
        regexName.test(`${fName && fName[Object.keys(fName)[0]]}`) &&
        regexName.test(`${lName && lName[Object.keys(lName)[0]]}`);
    }
    return regbool;
  }, [fName, mName, lName]);

  const disable = useMemo(() => {
    let firstname = "";
    let lastname = "";

    if (fName) {
      firstname = fName[Object.keys(fName)[0]];
    }
    if (lName) {
      lastname = lName[Object.keys(lName)[0]];
    }
    return firstname.length > 0 && lastname.length > 0 && irregularText;
  }, [fName, lName, irregularText]);

  return (
    <Stack direction="row" spacing={1} my={2}>
      <Box width="20%">
        <Typography component="label" fontSize="16px" lineHeight="2">
          First Name{" "}
          <Typography variant="inherit" display="inline" color="error.main">
            *
          </Typography>
        </Typography>
        <OutlinedInput
          required
          id={`outlined-first-name-${id}`}
          type="text"
          size="small"
          onChange={setName}
          error={!regexName.test(`${fName && fName[Object.keys(fName)[0]]}`)}
          name={`first_name_${id}`}
          sx={{
            borderRadius: "13px",
            backgroundColor: "white",
          }}
        />
      </Box>
      <Box width="20%">
        <Typography component="label" fontSize="16px" lineHeight="2">
          Middle Name{" "}
        </Typography>
        <OutlinedInput
          id={`outlined-middle-name-${id}`}
          type="text"
          size="small"
          onChange={setName}
          error={
            mName && mName[Object.keys(mName)[0]].length === 0
              ? false
              : !regexName.test(`${mName && mName[Object.keys(mName)[0]]}`)
          }
          name={`middle_name_${id}`}
          sx={{
            borderRadius: "13px",
            backgroundColor: "white",
          }}
        />
      </Box>
      <Box width="20%">
        <Typography component="label" fontSize="16px" lineHeight="2">
          Last Name{" "}
          <Typography variant="inherit" display="inline" color="error.main">
            *
          </Typography>
        </Typography>
        <OutlinedInput
          required
          id={`outlined-last-name-${id}`}
          type="text"
          size="small"
          onChange={setName}
          error={!regexName.test(`${lName && lName[Object.keys(lName)[0]]}`)}
          name={`last_name_${id}`}
          sx={{
            borderRadius: "13px",
            backgroundColor: "white",
          }}
        />
      </Box>
      <Box width="40%">
        <Typography component="label" fontSize="16px" lineHeight="2">
          Upload Director ID{" "}
          <Typography variant="inherit" display="inline" color="error.main">
            *
          </Typography>
        </Typography>

        <FileUploadComponent
          text="Director's ID Copy"
          nameValue={`director_id_copy_${id}`}
          bring={dirIdAdd}
          required
          dis={!disable}
          hideDelete={true}
        />
      </Box>
    </Stack>
  );
}
