import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Backdrop,
  Button,
  Card,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { usePostQuery } from "../../../customHooks/reactQuery";
import AuthContext from "../../../context/AuthContext";
import { toast } from "react-toastify";
import NavbarProcess from "../../../compnents/navs/NavbarProcess";
import PageLayout from "../../Templates/PageLayout";
import Cookies from "js-cookie";

// OTP Library input field linrary
import OtpInput from "react-otp-input";
// CUSTOM COMPONENTS
import InputElement from "../../../compnents/2FAInputElement";

export default function TwoFactorAuthEmail() {
  const navigate = useNavigate();
  const ctxAuth = useContext(AuthContext);

  const [formValues, setFormValues] = useState<any>({
    token: "",
  });
  const [otp, setOtp] = useState("");

  const {
    data,
    mutate: mutateGenerate,
    error: errorGenerate,
    isError: isErrorPost,
    isSuccess: isSuccessPost,
    isLoading: isLoadingGenerate,
  } = usePostQuery(
    "auth/otp/generate-email",
    ctxAuth.companyDetails.tokenAccess
  );

  const {
    data: dataVerify,
    error,
    mutate: mutateVerify,
    isSuccess,
  } = usePostQuery("auth/otp/verify-email", ctxAuth.companyDetails.tokenAccess);

  useEffect(() => {
    if (isSuccessPost) {
      if (data) {
        toast.success("Token successfully sent to your email.");
      }
    } else if (isErrorPost) {
      toast.error(
        "An error occured couldn't send verification token to your email. Try again or contact support for help."
      );
    }
  }, [data, errorGenerate, isErrorPost, isSuccessPost]);

  useEffect(() => {
    if (isSuccess && dataVerify?.data?.otp_email_verified) {
      toast.success("Token successfully verified");
      Cookies.set("otpValidated", "true");
      navigate("/security-settings");
    }
  }, [isSuccess, dataVerify?.data?.otp_verified]);

  const handleInputChange = (otp: string) => {
    const value = otp.trim();

    setFormValues({
      ...formValues,
      token: value,
    });

    setOtp(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    mutateVerify(formValues);
  };

  return (
    <PageLayout
      contentStyling={{ margin: "2rem" }}
      showBackgroundImage={false}
      CustomNavbar={<NavbarProcess />}
    >
      <>
        <Backdrop
          sx={{
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoadingGenerate}
        >
          <Typography
            variant="inherit"
            textAlign={"center"}
            color={"white"}
            my={2}
          >
            Generating token and sending token...
          </Typography>
        </Backdrop>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          minHeight={"70vh"}
        >
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              p: 4,
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              fontWeight="bold"
              mb={2}
            >
              Code verification
            </Typography>
            <Typography
              variant="body1"
              textAlign={"center"}
              lineHeight={2}
              mb={2}
            >
              We've sent a verification code to your email address. Please check
              your inbox and enter the code to complete 2FA setup.
            </Typography>
            <form onSubmit={handleSubmit}>
              <FormControl variant="outlined" fullWidth>
                {/* <OutlinedInput
                  required
                  id="outlined-required"
                  type="text"
                  name="token"
                  placeholder="123456"
                  onChange={handleInputChange}
                  data-cy="token-input-field"
                  sx={{
                    borderRadius: "13px",
                    backgroundColor: "white",
                    marginBottom: "40px",
                  }}
                /> */}
                <OtpInput
                  value={otp}
                  onChange={handleInputChange}
                  numInputs={6}
                  containerStyle={{ justifyContent: "center" }}
                  inputStyle={{
                    flex: 1,
                    border: "1px solid grey",
                  }}
                  renderSeparator={<span style={{ margin: ".2rem" }}> </span>}
                  renderInput={({ ...props }) => (
                    <InputElement
                      required
                      sx={{ marginY: "20px" }}
                      {...props}
                      data-cy="token-input-field"
                    />
                  )}
                />
              </FormControl>
              {error && (
                <Alert severity={"error"}>
                  {error?.response?.data?.message}
                </Alert>
              )}
              <Box display={"flex"} flexDirection={"row"} sx={{ gap: "5%" }}>
                <Button
                  variant="contained"
                  fullWidth
                  type="submit"
                  data-cy="email-verify-btn"
                >
                  verify
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  onClick={() => mutateGenerate(new FormData())}
                >
                  resend token
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </>
    </PageLayout>
  );
}
